import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AppBar, Toolbar } from "@material-ui/core";
import { IconButton, Badge } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import config from "../../config.json";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { CartState } from "../../services/Context";

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "#f8cc00",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuIcon: {
    color: "#7a6a24",
    fontWeight: "bold",
  },
});

const LogoURL = config.HEADER_LOGO;

const Logo = styled.img`
  padding: 15px;
  max-width: 30%;
  height: auto;
`;

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { RID, tableId } = useParams();
  const [count, setCount] = useState();
  const {
    state: { cart },
  } = CartState();

  const handleCartClick = () => {
    navigate(`/cart/${RID}/${tableId}`);
  };
  
  const handleHomeClick = () => {
    navigate(`/order/${RID}/${tableId}`);
  };

  const handleMenuNavigate = () => {
    navigate(`/order/${RID}/${tableId}`);
  };

  useEffect(() => {
    const total = cart.reduce(
      (accumulatedQuantity, cartItem) =>
        accumulatedQuantity + cartItem.quantity,
      0
    );

    setCount(total);
  }, [cart]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          aria-label="home"
          onClick={handleMenuNavigate}
          disabled={(window.location.href.indexOf("/order/") > -1) ? true : false}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Logo onClick={handleHomeClick} src={LogoURL} />
        <IconButton
          edge="end"
          aria-label="cart"
          onClick={handleCartClick}
          onChange={() => console.log("item added")}
          disabled={cart.length < 1 ? true : false}
        >
          <Badge badgeContent={count} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
