import React, { useState, useContext } from "react";
import {
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Snackbar,
  Alert,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { makeStyles } from "@material-ui/core/styles";

import MenuTabPanel from "./MenuTabPanel";

import { Cart } from "../../services/Context";

const useStyles = makeStyles({
  itemName: {
    color: "#292929",
    fontWeight: "bold",
  },
  priceOfItem: {
    fontStyle: "italic",
  },
  itemDescription: {
    color: "#757575",
  },
});

const MenuTabs = ({ menu, initval }) => {
  const [value, setValue] = useState(initval);
  const [increaseNotification, setIncreaseNotification] = useState(false);
  const classes = useStyles();
  const {
    state: { cart },
    dispatch,
  } = useContext(Cart);

  const handleSnackClick = () => {
    setIncreaseNotification(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIncreaseNotification(false);
  };

  const handleChange = (e, value) => {
    setValue(value);
  };

  return (
    <div>
      <Snackbar increaseNotification={increaseNotification} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Item Added to Cart.
        </Alert>
      </Snackbar>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {menu.map(({ Category }) => {
          return (
            <Tab
              label={Category}
              value={Category}
              index={Category}
              key={Category}
              sx={{
                fontFamily: "HurmeGeometricSans2-Black",
              }}
            ></Tab>
          );
        })}
      </Tabs>
      {menu.map(({ Category, FoodItems }) => {
        return FoodItems.map(({ Description, Name, Price }, index) => {
          return (
            <MenuTabPanel value={value} index={Category} key={Name}>
              <Box
                sx={{
                  padding: "20px",
                  boxShadow: 2,
                  display: "grid",
                  gridAutoColumns: "1fr",
                  gap: 1,
                }}
              >
                <Box sx={{ gridRow: "1", gridColumn: "span 3" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "HurmeGeometricSans2-Black",
                    }}
                    className={classes.itemName}
                  >
                    {Name}
                  </Typography>
                  <p className={classes.itemDescription}>{Description}</p>
                  <p className={classes.priceOfItem}>${Price}</p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gridRow: "1",
                    gridColumn: "4 / 5",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="add to shopping cart"
                    key={Name}
                    onClick={() => {
                      dispatch({
                        type: "ADD_TO_CART",
                        payload: { Category, Description, Name, Price },
                      });
                      handleSnackClick();
                    }}
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </Box>
              </Box>
            </MenuTabPanel>
          );
        });
      })}
    </div>
  );
};

export default MenuTabs;
