import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './index.css';

import App from './App';
import Menu from './pages/MenuPage/MenuPage';
import CartPage from './pages/CartPage/CartPage';
import Context from "./services/Context"
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import Error from "./components/error/error.component"

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Context>
          <Routes>
            <Route path='/' element={<App />} >
              <Route path="order/:RID/:tableId" element={<Menu />} />
              <Route path="cart/:RID/:tableId" element={<CartPage />} />
              <Route path="confirmation/:RID/:tableId" element={<ConfirmationPage />} />
              <Route path="*" component={Error} />
            </Route>
          </Routes>
      </Context>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);