import React from "react";
import "./sadface.css";

const SadFace = () => {
  return (
    <div className="container">
      <div className="tear"></div>
      <div className="tear2"></div>
      <div className="face">
        <div className="eyebrow">︶</div>
        <div className="eyebrow">︶</div>
        <div className="eye"></div>
        <div className="eye"></div>
        <div className="mouth"></div>
      </div>
    </div>
  );
};

export default SadFace;
