import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { Cart } from "../../services/Context";
import { makeReservation } from "../../services/Restaurant";
import { ReactComponent as AppleLogo } from "../../assets/AppleBadge.svg";
import { ReactComponent as GoogleLogo } from "../../assets/GoogleBadge.svg";
import ReservationCode from "../../services/ReservationCode";

const ConfirmationPage = () => {
  const {
    state: { cart },
    dispatch,
  } = useContext(Cart);
  const { RID, tableId } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/order/${RID}/${tableId}`);
  };

  const handleGoogleClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.tavolo.android&hl=en_US&gl=US";
  };

  const handleAppleClick = () => {
    window.location.href =
      "https://apps.apple.com/us/app/tavolo-dining-made-faster/id1457511014";
  };

  const [error,setError] = useState(false);

  useEffect(() => {
    try {
      const makeResCall = async () => {
        const data = await makeReservation;
      };
    } catch (e) {
      console.log(e);
      setError(true);
    }
  });

  const buttonLogoStyle = {
    width: "200px",
    height: "auto",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "20px"
  };

  const reservationCodeForOrder = ReservationCode.getInstance();


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
        boxShadow: 2,
        fontFamily: "HurmeGeometricSans2-Black",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >{
      error ? 
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontFamily: "HurmeGeometricSans2-Black",
        }}
      >
        Sorry! Something was wrong with your order. Please text +1-320-288-3975 for a refund!
      </Typography>
      :
      <>
      <Typography
      variant="h6"
      sx={{
        textAlign: "center",
        fontFamily: "HurmeGeometricSans2-Black",
      }}
    >
      Thank you. 
    </Typography>
    <Typography
      variant="h6"
      sx={{
        textAlign: "center",
        fontFamily: "HurmeGeometricSans2-Black",
      }}
    >
      Your order was confirmed!
    </Typography>
    <Typography
      variant="h6"
      sx={{
        textAlign: "center",
        fontFamily: "HurmeGeometricSans2-Black",
        color: "#757575"
      }}
    >
      Your Reservation Code is {reservationCodeForOrder}
    </Typography>
    </>
    }
      <GoogleLogo onClick={handleGoogleClick} style={buttonLogoStyle} />
      <AppleLogo onClick={handleAppleClick} style={buttonLogoStyle} />
    </Box>
  );
};

export default ConfirmationPage;
