import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Snackbar,
  Alert,
  Modal,
  TextField,
  ButtonGroup,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Cart } from "../../services/Context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ReservationCode from "../../services/ReservationCode";

import Checkout from "../../components/checkout/checkout.component";

import {
  getRestaurantDetails,
  makePaymentInit,
  makePaymentIntent,
} from "../../services/Restaurant";
import { formatDate } from "./CartPage.Utility";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
  },
  itemName: {
    color: "#7a6a24",
    fontWeight: "bold",
  },
  finalPrice: {
    position: "relative",
  },
  pricexAmount: {
    fontStyle: "italic",
  },
});

const CartPage = () => {
  const {
    state: { cart },
    dispatch,
  } = useContext(Cart);
  const { RID, tableId } = useParams();
  const navigate = useNavigate();
  const taxRate = 0.06875;
  const taxTipRate = 1.06875;
  const [total, setTotal] = useState();
  const [taxadded, setTaxadded] = useState();
  const [totalBeforeTip, setTotalBeforeTip] = useState();
  const [name, setName] = useState("");
  const [tip, setTip] = useState(0);
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [resType, setResType] = useState("RESERVATION");
  const [increaseNotification, setIncreaseNotification] = useState(false);
  const [decreaseNotification, setDecreaseNotification] = useState(false);
  const [deleteNotification, setDeleteNotification] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [stripeKey, setStripeKey] = useState("");
  const [clientSecretExists, setClientSecretExists] = useState(false);
  const [options, setOptions] = useState({
    clientSecret: "",
  });
  const [init, setInit] = useState(false);
  const [resData, setResData] = useState([]);
  const [restName, setRestName] = useState("");
  const classes = useStyles();

  // Hit the + button alert
  const handleIncreaseClick = () => {
    setIncreaseNotification(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIncreaseNotification(false);
    setDecreaseNotification(false);
    setDeleteNotification(false);
  };

  // Hit the - button alert
  const handleDecreaseClick = () => {
    setDecreaseNotification(true);
  };

  // Hit the "delete" button alert
  const handleDeleteClick = () => {
    setDeleteNotification(true);
  };

  const handleAddMoreButtonClick = () => {
    navigate("/order/" + RID + "/" + tableId);
  };

  // Stripe Payment Init - get the stripeKey
  const getStripe = async () => {
    try {
      const result = await makePaymentInit();
      setStripeKey(loadStripe(result));
    } catch (e) {
      console.log(e);
    }
  };

  // Payment Modal
  const handlePaymentModalOpen = () => setOpenPaymentModal(true);

  const handlePaymentModalClose = () => setOpenPaymentModal(false);

  // Order Confirm Modal
  const handleConfirmModalOpen = () => setOpenConfirmModal(true);

  const handleConfirmModalClose = () => setOpenConfirmModal(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(0.95)",
    width: 400,
    bgcolor: "background.paper",
    padding: "20px",
    boxShadow: 2,
    display: "grid",
    justifyContent: "center",
  };

  // Form
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // Email Input Checker
  const handleEmailChange = (e) => {
    const emailChecker =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const val = e.target.value;

    setIsDisabled(!emailChecker.test(e.target.value));

    setEmail(val);
  };

  const handleTipChange = (e) => {
    setTip(parseFloat(e.target.value));
  };

  const totalcalc = parseFloat(
    (parseFloat(tip) + parseFloat(total) + parseFloat(taxadded)).toFixed(2)
  );

  const orderListForBackend = (orders) => {
    const filteredOrders = [];

    orders.forEach((order) => {
      for (var i = 1; i <= order.quantity; i++) {
        filteredOrders.push({
          Category: order.Category,
          Description: order.Description,
          Name: order.Name,
          Price: order.Price,
        });
      }
    });
    return filteredOrders;
  };

  const reservationCodeForOrder = ReservationCode.getInstance();

  const dataToSend = {
    customerName: name,
    customerId: `Table ${tableId} for ${restName}`,
    email: email,
    ReservationCode: reservationCodeForOrder,
    MenuItemCost: total,
    NumberOfSeats: 1,
    Orders: [...orderListForBackend(cart)],
    RestaurantName: restName,
    ReservationStartTime: formatDate(new Date()),
    RID: RID,
    Status: "NOT_ARRIVED",
    Tax: taxadded,
    Tip: tip,
    Total: totalcalc,
    Type: resType,
  };

  const getStripePaymentIntent = async () => {
    try {
      const result = await makePaymentIntent(dataToSend);
      const dataNew = result.paymentIntent;
      const data = { ...options };
      data.clientSecret = dataNew;
      dataNew !== "" ? setClientSecretExists(true) : setClientSecretExists(false);
      setResData(dataToSend);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  // Form Submit
  // Opens the "Proceed To Checkout" Modal
  // Fires the makePaymentIntent lambda and sets up the prop to be passed to the Stripe PaymentElement
  // The idea of the modal is to be a step to create the necessary props to open the Stripe PaymentElement
  const handleInfoSubmit = async () => {
    // Function to handle the lambda call
    const getOptionsData = async () => {
      const optionsData = await getStripePaymentIntent();
      setOptions(optionsData);
    };
    await getOptionsData();

    init === false ? setInit(true) : setInit(false);
    handleConfirmModalOpen();
  };

  // Closes the confirmation modal and opens the modal containing Stripe PaymentElement
  const handleCheckoutConfirm = () => {
    handleConfirmModalClose();
    handlePaymentModalOpen();
  };

  const buttonStyle = {
    backgroundColor: "#f8cc00",
    color: "black",
    fontFamily: "HurmeGeometricSans2-Black",
  };

  useEffect(() => {
    const totalPrice = cart.reduce(
      (accumulatedQuantity, cartItem) =>
        accumulatedQuantity + cartItem.quantity * cartItem.Price,
      0
    );
    setTotal(parseFloat(totalPrice.toFixed(2)));
    setTaxadded(parseFloat((totalPrice * taxRate).toFixed(2)));
    setTotalBeforeTip(parseFloat((totalPrice * taxTipRate).toFixed(2)));

    if (!stripeKey) {
      getStripe();
    }

    // RID Stuff
    const getRestaurantName = async () => {
      const data = await getRestaurantDetails(RID);
      setRestName(data.Name);
    };
    getRestaurantName();
  }, [cart, init, RID]);

  return (
    <div>
      <Box
        sx={{
          padding: "20px",
          boxShadow: 2,
          fontFamily: "HurmeGeometricSans2-Black",
        }}
      >
        <Typography
          variant="h5"
          className={classes.header}
          sx={{
            fontFamily: "HurmeGeometricSans2-Black",
          }}
        >
          Cart
        </Typography>
        {cart.map(({ Category, Description, Name, Price, quantity }, index) => {
          return (
            <Box
              value={Name}
              index={index}
              key={Name}
              sx={{
                padding: "20px",
                boxShadow: 2,
                display: "grid",
                gridAutoColumns: "1fr",
                gap: 1,
              }}
            >
              <Snackbar
                open={increaseNotification}
                autoHideDuration={3000}
                onClose={handleSnackClose}
              >
                <Alert
                  onClose={handleSnackClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Item quantity increased.
                </Alert>
              </Snackbar>
              <Snackbar
                open={decreaseNotification}
                autoHideDuration={3000}
                onClose={handleSnackClose}
              >
                <Alert
                  onClose={handleSnackClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Item quantity decreased.
                </Alert>
              </Snackbar>
              <Snackbar
                open={deleteNotification}
                autoHideDuration={3000}
                onClose={handleSnackClose}
              >
                <Alert
                  onClose={handleSnackClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Item removed.
                </Alert>
              </Snackbar>
              <Box sx={{ gridRow: "1", gridColumn: "span 3" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "HurmeGeometricSans2-Black",
                  }}
                  className={classes.itemName}
                >
                  {quantity > 1 ? `${Name} x ${quantity}` : Name}
                </Typography>
                <IconButton
                  aria-label="add to shopping cart"
                  key={Name}
                  onClick={() => {
                    dispatch({
                      type: "ADD_TO_CART",
                      payload: { Category, Description, Name, Price },
                    });
                    handleIncreaseClick();
                  }}
                >
                  <AddRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="remove to shopping cart"
                  key={Name}
                  onClick={() => {
                    dispatch({
                      type: "REMOVE_FROM_CART",
                      payload: { Category, Description, Name, Price },
                    });
                    handleDecreaseClick();
                  }}
                >
                  <RemoveRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="remove from shopping cart"
                  key={Name}
                  onClick={() => {
                    dispatch({
                      type: "DELETE_ITEM_FROM_CART",
                      payload: { Category, Description, Name, Price },
                    });
                    handleDeleteClick();
                  }}
                >
                  <DeleteOutlineRoundedIcon />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  display: "flex",
                  gridRow: "1",
                  gridColumn: "4 / 5",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                variant="p"
                className={classes.pricexAmount}
              >
                ${Price}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "20px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#f8cc00",
            color: "black",
            fontFamily: "HurmeGeometricSans2-Black",
          }}
          startIcon={<AddRoundedIcon />}
          onClick={handleAddMoreButtonClick}
        >
          ADD MORE ITEMS
        </Button>
      </Box>
      <Box
        sx={{
          padding: "20px",
          boxShadow: 2,
          display: "grid",
          gridAutoColumns: "1fr",
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "HurmeGeometricSans2-Black",
          }}
          className={classes.finalPrice}
        >
          Total: ${total}
        </Typography>
        {taxadded && (
          <Typography variant="p" className={classes.finalPrice}>
            {`+Taxes ${taxadded}`}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          padding: "20px",
          boxShadow: 2,
          fontFamily: "HurmeGeometricSans2-Black",
        }}
      >
        <Box>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
              padding: "20px",
              boxShadow: 2,
              fontFamily: "HurmeGeometricSans2-Black",
              display: "grid",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              flexFlow: "wrap",
            }}
            noValidate
            autoComplete="off"
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "HurmeGeometricSans2-Black",
                textAlign: "center",
              }}
              gutterBottom
              component="div"
            >
              Information
            </Typography>
            <TextField
              type="text"
              name="name"
              required
              label="Customer name"
              value={name}
              onChange={handleNameChange}
            />
            <TextField
              type="email"
              name="email"
              required
              label="Email for receipt 📧"
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              type="number"
              name="tip"
              label="Tip 💲"
              value={tip}
              onChange={handleTipChange}
            />
            <ButtonGroup variant="contained">
              <Button
                onClick={() => {
                  setTip(parseFloat((total * taxTipRate * 0.15).toFixed(2)));
                }}
                sx={buttonStyle}
              >
                15%
              </Button>
              <Button
                onClick={() => {
                  setTip(parseFloat((total * taxTipRate * 0.18).toFixed(2)));
                }}
                sx={buttonStyle}
              >
                18%
              </Button>
              <Button
                onClick={() => {
                  setTip(parseFloat((total * taxTipRate * 0.2).toFixed(2)));
                }}
                sx={buttonStyle}
              >
                20%
              </Button>
              <Button
                onClick={() => {
                  setTip(parseFloat((total * taxTipRate * 0.22).toFixed(2)));
                }}
                sx={buttonStyle}
              >
                22%
              </Button>
              <Button
                onClick={() => {
                  setTip(parseFloat((total * taxTipRate * 0.25).toFixed(2)));
                }}
                sx={buttonStyle}
              >
                25%
              </Button>
            </ButtonGroup>
            <Button
              disabled={cart.length < 1 || !name || isDisabled}
              variant="contained"
              sx={{
                backgroundColor: "#f8cc00",
                color: "black",
                fontFamily: "HurmeGeometricSans2-Black",
              }}
              onClick={handleInfoSubmit}
            >
              CONTINUE
            </Button>
          </Box>
          <Modal open={openConfirmModal} onClose={handleConfirmModalClose}>
            <Box sx={modalStyle}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "HurmeGeometricSans2-Black",
                }}
                className={classes.itemName}
              >
                Proceed To Checkout?
              </Typography>
              <Button
                disabled={cart.length < 1 || !name || isDisabled}
                variant="contained"
                sx={{
                  backgroundColor: "#f8cc00",
                  color: "black",
                  fontFamily: "HurmeGeometricSans2-Black",
                }}
                onClick={handleCheckoutConfirm}
              >
                CHECKOUT
              </Button>
            </Box>
          </Modal>
          <Modal open={openPaymentModal} onClose={handlePaymentModalClose}>
            <Box sx={modalStyle}>
              {stripeKey && clientSecretExists && (
                <Elements options={options} stripe={stripeKey}>
                  <Checkout resData={resData} options={options} />
                </Elements>
              )}
            </Box>
          </Modal>
        </Box>
      </Box>
    </div>
  );
};

export default CartPage;
