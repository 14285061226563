import React from "react";
import styled from "styled-components";
import SadFace from './sadface';

const ErrorContainer = styled.div`
`;

const Text = styled.div`
  width: 50%;
  margin: auto;
  text-align: center;
`

const Error = props => {
  return (
    <ErrorContainer>
      <Text>
          <h3>Sorry, we ran into an error.</h3>
          <h4>It looks like you scanned an invalid QR which is outdated or invalid.</h4>
      </Text>
      <SadFace />
    </ErrorContainer>
  );
}

export default Error;
