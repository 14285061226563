import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Typography } from "@mui/material/";

import { getRestaurantDetails } from "../../services/Restaurant";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    padding: "20px",
    maxWidth: "18%",
  },
  nameStyle: {
    fontWeight: 400,
  },
  priceStyle: {
    fontWeight: 400,
    fontStyle: "italic",
  },
}));

const TitleContainer = () => {
  let [address, setAddress] = useState(null);
  let [hours, setHours] = useState();
  let [name, setName] = useState();
  let [cover, setCover] = useState("");
  const { RID, tableId } = useParams();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRestaurantDetails(RID);

        setName(`${response.Name}`);
        setCover(`${response.coverphotoURL}`);
        setAddress(
          `${response.Location.AddressLine1} ${response.Location.City} ${response.Location.State} ${response.Location.Zip}`
        );

        switch (new Date().getDay()) {
          case 0:
            setHours(response.Hours[0].Sunday);
            break;
          case 1:
            setHours(response.Hours[0].Monday);
            break;
          case 2:
            setHours(`${response.Hours[0].Tuesday}`);
            break;
          case 3:
            setHours(response.Hours[0].Wednesday);
            break;
          case 4:
            setHours(response.Hours[0].Thursday);
            break;
          case 5:
            setHours(response.Hours[0].Friday);
            break;
          case 6:
            setHours(response.Hours[0].Saturday);
            break;
          default:
            setHours("Closed");
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="140"
          image={cover}
          alt="restaurant image"
        />
        <CardContent>
          <Typography
            sx={{
              fontFamily: "HurmeGeometricSans2-Black",
            }}
            gutterBottom
            variant="h5"
            component="div"
            className={classes.nameStyle}
          >
            {name}
          </Typography>
          <Typography variant="p" color="text.secondary">
            {address}
            <br />
            {hours}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default TitleContainer;
