import axios from "axios";

const API_GATEWAY_URL = (window.location.href.includes("localhost") || window.location.href.includes("test")) ? 
    "https://ql264w3bgj.execute-api.us-east-1.amazonaws.com/test" : 
    "https://9dtvnkt7f2.execute-api.us-east-1.amazonaws.com/prod";

export const getRestaurantDetails = (RID) => {
    const API_URL = API_GATEWAY_URL + '/getRestaurantDetails/' + RID;
    return axios.get(API_URL).then(({data}) => {
        return data;
    }).catch(e =>{
        console.error(e);
        throw "restaurant not found";
    })

}

export const getRestaurantMenu = (RID) => {
    const API_URL = API_GATEWAY_URL + '/getRestaurantMenu/' + RID;
    return axios.get(API_URL).then(({data})=> {
        return data;
    }).catch(e => {
        console.error(e);
        throw "Restaurant Menu Not Found"
    })
}

export const makePaymentInit = () => {
    const API_URL = API_GATEWAY_URL + '/paymentInIt';
    return axios.get(API_URL).then(({ data }) => {
        return data.publishingKey;
    }).catch(e => {
        console.error(e);
        throw "Publishing Key Not Found"
    })
}

export const makePaymentIntent = (reservationData) => {
    const API_URL = API_GATEWAY_URL + '/makePaymentIntent/';
    return axios.post(API_URL, reservationData).then(({data})=> {
        return data;
    }).catch(e => {
        console.error(e);
        throw "There was an error initializing the payment sheet"
        console.log(e);
    })
}

export const makeReservation = (reservationData) => {
    const API_URL = API_GATEWAY_URL + '/makeReservation/';
    return axios.post(API_URL, reservationData).then(({data})=> {
        return data;
    }).catch(e => {
        console.error(e);
        throw "Error making reservation"
    })
}