import { useState, useContext, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { Cart } from "../../services/Context";
import { makeReservation } from "../../services/Restaurant";

const Checkout = ({ resData, options }) => {
  const {
    state: { cart },
    dispatch,
  } = useContext(Cart);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderPaymentElement, setRenderPaymentElement] = useState(true);
  const { RID, tableId } = useParams();
  const navigate = useNavigate();

  const resetCart = () => {
    dispatch({
      type: "EMPTY_CART",
      payload: { cart },
    });
  };

  const handleConfirmation = () => {
    resetCart();
    setRenderPaymentElement(false);
    setRenderPaymentElement(true);
    navigate(`/confirmation/${RID}/${tableId}`);
  };

  useEffect(() => {
    if (stripe && elements) {
      setRenderPaymentElement(true);
    }
  }, [stripe, elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    if (result.error) {
      console.log(JSON.stringify(result));
      setMessage("An unexpected error occured.");
    } else {
      const clientSecret = options.clientSecret;
      const data = await makeReservation({
        ...resData,
        paymentIntent: clientSecret,
      });

      if (data.ReservationId) {
        handleConfirmation();
      }
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {renderPaymentElement && <PaymentElement id="payment-element" />}
      {!renderPaymentElement && (
        <Typography
          variant="h6"
          sx={{
            fontFamily: "HurmeGeometricSans2-Black",
            textAlign: "center",
          }}
          gutterBottom
          component="div"
        >
          Oops! There was an issue. Please refresh this page!
        </Typography>
      )}
      <LoadingButton
        sx={{
          backgroundColor: "#f8cc00",
          color: "black",
          fontFamily: "HurmeGeometricSans2-Black",
          mt: "10px",
        }}
        disabled={isLoading}
        loading={isLoading}
        variant="contained"
        onClick={handleSubmit}
      >
        Pay Now
      </LoadingButton>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default Checkout;
