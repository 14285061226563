import React from "react";
import { Box } from "@mui/material";


const MenuTabPanel =({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}

export default MenuTabPanel;
