import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0px;
  top: 100vh;
  width: 100%;
  background-color: black;
  color: white;
`;

const Text = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const Footer = props => {
  return (
    <FooterContainer>
      <Text>
      Powered 🔋 by Tavolo Inc 2022 © 
        </Text>
    </FooterContainer>
  );
}

export default Footer;
