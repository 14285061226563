import React from "react";
import styled from "styled-components";

import "./App.css";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import { Outlet } from "react-router-dom";

const Main = styled.div`
  justify-content: center;
  align-items: center;
`;

const App = () => {
  
  return (
    <div>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </div>
  );
}

export default App;