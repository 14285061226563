export const addItemToCart = (cart, cartItemToAdd) => {
    const existingCartItem = cart.find(
        cartItem => cartItem.Name === cartItemToAdd.Name
    )

    if (existingCartItem) {
        return cart.map(
            cartItem =>
            cartItem.Name === cartItemToAdd.Name ?
            { ...cartItem, quantity: cartItem.quantity + 1}
            : cartItem
        )
    }

    return [...cart, { ...cartItemToAdd, quantity: 1 }]
};

export const removeItemFromCart = (cart, cartItemToRemove) => {
    const existingCartItem = cart.find(
        cartItem => cartItem.Name === cartItemToRemove.Name
    )

    if (existingCartItem.quantity === 1) {
        return cart.filter(
            cartItem => cartItem.Name !== cartItemToRemove.Name
        )
    }

    return cart.map(
        cartItem =>
        cartItem.Name === cartItemToRemove.Name ?
        {...cartItem, quantity: cartItem.quantity - 1}
        : cartItem
    );
}

export const deleteItemFromCart = (cart, cartItemToDelete) => {
    const existingCartItem = cart.find(
        cartItem => cartItem.Name === cartItemToDelete.Name
    )
    if (existingCartItem.Name === cartItemToDelete.Name) {
        return cart.filter(
            cartItem => cartItem.Name !== cartItemToDelete.Name
        )
    }
}

export const cartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_CART":
            return {
                ...state,
                cart: addItemToCart(state.cart, action.payload)
            };
        case "REMOVE_FROM_CART":
            return {
                ...state,
                cart: removeItemFromCart(state.cart, action.payload)
            };
        case "DELETE_ITEM_FROM_CART":
            return {
              ...state,
              cart: deleteItemFromCart(state.cart, action.payload)
            };
        case "EMPTY_CART":
            return {
                ...state,
                  cart: []
            };
      default:
        return state;
    }
  };

