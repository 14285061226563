import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import MenuTabs from "./MenuTabs";
import TitleContainer from "../../components/title-container/title-container.component";

import {
  getRestaurantMenu,
} from "../../services/Restaurant";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const Menu = () => {
  const { RID, tableId } = useParams();
  const styles = useStyles();
  const [menu, setMenu] = useState([]);
  const [initval, setInitval] = useState();

  const menuRes = async () => {
    const restMenu = await getRestaurantMenu(RID);
    setInitval(Object.values(restMenu[RID])[0].Category);
  };

  useEffect(() => {
    const setUp = async () => {
      try {
        const menuRes = await getRestaurantMenu(RID);
        setMenu(menuRes[RID]);
      } catch (e) {
        console.log(e);
      }
    };
    setUp();
  }, [RID, tableId]);

  if (menu) {
    menuRes();
  }

  return (
    <div className={styles.root}>
      {menu && initval && (
        <>
          <TitleContainer />
          <MenuTabs menu={menu} initval={initval} />
        </>
      )}
    </div>
  );
};

export default Menu;
