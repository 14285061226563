export default class ReservationCode {
  constructor() {}

  static getInstance() {
    if (!this.instance) {
      const makeId = (length) => {
        var result = "";
        var characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      };

      const ReservationCode = makeId(9);
      this.instance = ReservationCode;
    }
    return this.instance;
  }
}
